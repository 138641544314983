import React, { useEffect, useState } from 'react'
import { LocationService } from '@cgtalks/common/service'
import ShareCgOnline from './share-cg-online'
import { API, Axios } from '@cgtalks/common/utils'

const ShareCgOnlineSearch = ({ location }) => {
  const paramsMap = LocationService.getLocationSearchParams()
  const [searchData, setSearchDate] = useState([])
  const [searchTotal, setSearchTotal] = useState(0)
  const page = paramsMap.get('page')
  const title = paramsMap.get('title')
  const software = paramsMap.get('software')
  const year = paramsMap.get('year')
  const instance = new Axios(API.auth.videoHost, false)

  const getQueryDataList = async () => {
    const response = await instance.post(API.event.cgOnlineSearchResult, {
      pageSize: 9,
      pageNum: +page,
      title: title,
      software: software,
      year: year,
    })
    setSearchTotal(response?.total || 0)
    setSearchDate([...(response?.data || [])])
  }

  useEffect(() => {
    if (!page) return
    getQueryDataList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, title, software, year])

  return (
    <ShareCgOnline
      location={location}
      isSearchPage={true}
      pageContext={{
        currentPage: +page,
        numPages: Math.ceil(searchTotal / 9),
        sourceData: searchData,
        totalNum: searchTotal,
      }}
    />
  )
}

export default ShareCgOnlineSearch
